/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import i18nContext from 'components/i18n-context';
import AmountInput from 'components/common/AmountInput';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import Loader from 'components/common/Loader';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { getAccountsOptionsForSelect } from 'components/utils';
import { ROUTE_PATHS } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { amountFormattedValue, findAccountByCurrency, MEDIA_QUERY, prepareFieldErrors } from 'services/utils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './BetweenAccounts.scss';

const BetweenAccounts = ({ userAccounts, customerNumber, topUpAccount, betweenAccountsStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const navigate = useNavigate();

  const getServerFieldsErrors = (variable) => prepareFieldErrors(i18n, betweenAccountsStore.error, variable);

  useEffect(() => {
    if (!betweenAccountsStore.isRepeatTransaction && !betweenAccountsStore.isRedirectFromPaymentForm) {
      betweenAccountsStore.resetStore();
      if (betweenAccountsStore.isCardAccountTopUp) {
        betweenAccountsStore.setIsRedirectToCards(true);
        betweenAccountsStore.setIsCardAccountTopUp(false);
      }
    } else if (betweenAccountsStore.isRepeatTransaction) {
      betweenAccountsStore.setIsRepeatTransactionStatus(false);
      validateForm(values);
    } else if (betweenAccountsStore.isRedirectFromPaymentForm) {
      betweenAccountsStore.setIsRedirectFromPaymentFormStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!betweenAccountsStore.isInitialized && userAccounts.length > 0) {
      if (topUpAccount) {
        handleChangeAccountTo('accountTo', topUpAccount?.wallet_number);
      } else {
        const accountFrom = findAccountByCurrency({ accounts: userAccounts });
        const accountTo = userAccounts.find(
          (account) =>
            account?.wallet_number !== accountFrom?.wallet_number && account?.currency === accountFrom?.currency
        );
        betweenAccountsStore.setFromToAccounts(accountFrom, accountTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccounts, betweenAccountsStore.isInitialized]);

  useEffect(() => {
    if (betweenAccountsStore.isInternalTransferCompleted) {
      userStore.getUserAccounts();
      betweenAccountsStore.setIsInternalTransferCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betweenAccountsStore.isInternalTransferCompleted]);

  const handleChangeAccountFrom = (name, value) => {
    const selectedAccount = userAccounts.find((account) => account?.wallet_number === value);
    const accountTo = userAccounts.find((account) =>
      betweenAccountsStore.accountTo?.wallet_number === selectedAccount?.wallet_number ||
      betweenAccountsStore.accountTo?.currency !== selectedAccount?.currency
        ? account?.wallet_number !== selectedAccount?.wallet_number && account?.currency === selectedAccount?.currency
        : account?.wallet_number === betweenAccountsStore.accountTo?.wallet_number
    );
    betweenAccountsStore.setFromToAccounts(selectedAccount, accountTo);
  };

  const handleChangeAccountTo = (name, value) => {
    const selectedAccount = userAccounts.find((account) => account?.wallet_number === value);
    const accountFrom = userAccounts.find((account) =>
      betweenAccountsStore.accountFrom?.wallet_number === selectedAccount?.wallet_number ||
      betweenAccountsStore.accountFrom?.currency !== selectedAccount?.currency
        ? account?.wallet_number !== selectedAccount?.wallet_number && account?.currency === selectedAccount?.currency
        : account?.wallet_number === betweenAccountsStore.accountFrom?.wallet_number
    );

    betweenAccountsStore.setFromToAccounts(accountFrom, selectedAccount);
  };

  const getAvailableBalance = (account) =>
    account &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(account.available),
      currency: account.currency
    });

  const accountsOptions = getAccountsOptionsForSelect(userAccounts);

  const isBalanceExceeded = (amount, balance) => {
    return amount > balance;
  };

  const handleCheckInternalTransaction = async () => {
    if (betweenAccountsStore.amount && betweenAccountsStore.accountFrom && betweenAccountsStore.accountTo) {
      if (isBalanceExceeded(Number(betweenAccountsStore.amount), betweenAccountsStore?.accountFrom?.available)) {
        betweenAccountsStore.setError({ fields: { amount: 'balanceExceeded' } });
      } else {
        betweenAccountsStore.setError(null);
        await betweenAccountsStore?.checkInternalTransaction(customerNumber);
      }
    }
  };

  const handleVerifyInternalTransaction = async (securityCode) => {
    await betweenAccountsStore.verifyInternalTransaction(customerNumber, securityCode);
  };

  const handleCloseSuccessModal = () => {
    if (betweenAccountsStore.isRedirectToCards) {
      navigate(ROUTE_PATHS.CARDS);
    }
    betweenAccountsStore.resetStore();
  };

  const handleValidateField = (fieldName) => {
    betweenAccountsStore.setError(null);
    if (fieldName === 'amount') {
      setFieldValue(fieldName, values.amount.replace(/\.+$/, ''));
    }
    validateField(fieldName);
  };

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      amount: betweenAccountsStore.isRepeatTransaction ? betweenAccountsStore.amount : '',
      comment:
        betweenAccountsStore.isRepeatTransaction && betweenAccountsStore.description
          ? betweenAccountsStore.description
          : ''
    },
    initialStatus: { amount: true, comment: true },
    validationSchema: Yup.object({
      amount: Yup.string()
        .required(i18n.getMessage('betweenAccounts.error.amountRequired'))
        .test('balanceExceeded', i18n.getMessage('error.fieldError.amount.balanceExceeded'), function () {
          const { accountFrom } = betweenAccountsStore;
          const { amount } = this.parent;
          return !(amount && accountFrom && isBalanceExceeded(Number(amount), accountFrom.available));
        }),
      comment: Yup.string().nullable(true).max(255, i18n.getMessage('betweenAccounts.error.descriptionMaxSize'))
    }),
    onSubmit: async (values) => {
      betweenAccountsStore.setAmount(values.amount);
      betweenAccountsStore.setDescription(values.comment);
      handleCheckInternalTransaction().then(() => {
        if (!betweenAccountsStore.error) {
          betweenAccountsStore?.setIsInternalTransactionCreated(true);
        }
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, validateForm, isValid, setFieldValue } = form;

  return (
    <>
      {userAccounts.length === 0 ? (
        <ContactUs
          icon={<SupportIcon />}
          header={i18n.getMessage('betweenAccounts.noAccounts.title')}
          description={i18n.getMessage('betweenAccounts.noAccounts.description')}
          button={i18n.getMessage('betweenAccounts.noAccounts.button')}
        />
      ) : (
        <section className={'right-section'}>
          <Container
            className={!isMobile ? 'container-web' : ''}
            header={i18n.getMessage('betweenAccounts.container.label')}
            isMaxWidthUnset={true}
          >
            <form className={'between-accounts-wrapper'} onSubmit={handleSubmit}>
              <div className={'between-accounts-inputs-wrapper'}>
                <InputDropDown
                  isRequired={true}
                  name={'accountFrom'}
                  topLabel={getAvailableBalance(betweenAccountsStore.accountFrom)}
                  value={betweenAccountsStore.accountFrom?.wallet_number}
                  currency={betweenAccountsStore.accountFrom?.currency}
                  options={accountsOptions}
                  onChange={handleChangeAccountFrom}
                  label={i18n.getMessage('betweenAccounts.form.from')}
                  isMulti={false}
                  error={!betweenAccountsStore.accountFrom ? betweenAccountsStore.error : ''}
                />
                <InputDropDown
                  isRequired={true}
                  name={'accountTo'}
                  topLabel={getAvailableBalance(betweenAccountsStore.accountTo)}
                  value={betweenAccountsStore.accountTo?.wallet_number}
                  currency={betweenAccountsStore.accountTo?.currency}
                  options={accountsOptions}
                  onChange={handleChangeAccountTo}
                  label={i18n.getMessage('betweenAccounts.form.to')}
                  isMulti={false}
                  error={!betweenAccountsStore.accountTo ? betweenAccountsStore.error : ''}
                />
                <AmountInput
                  isRequired={true}
                  label={i18n.getMessage('betweenAccounts.form.amount')}
                  name={'amount'}
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={() => handleValidateField('amount')}
                  placeholder={i18n.getMessage('betweenAccounts.input.amount.placeholder')}
                  error={
                    errors.amount ||
                    getServerFieldsErrors(amountFormattedValue(betweenAccountsStore.exchangeRate?.min))?.amount
                  }
                />
                <Input
                  type={'textarea'}
                  className={'between-accounts-description-input'}
                  name={'comment'}
                  label={i18n.getMessage('betweenAccounts.input.description.label')}
                  placeholder={i18n.getMessage('betweenAccounts.input.description.placeholder')}
                  rows={3}
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={() => handleValidateField('comment')}
                  error={errors.comment || getServerFieldsErrors()?.comment}
                />
              </div>
              <div className='between-accounts-action-wrapper'>
                <Button
                  type={'primary'}
                  roleType={'submit'}
                  size={'large'}
                  isDisabled={!isValid || betweenAccountsStore.isLoading || betweenAccountsStore.error}
                >
                  {betweenAccountsStore.isLoading ? <Loader /> : i18n.getMessage('betweenAccounts.button.transfer')}
                </Button>
              </div>
            </form>
          </Container>
          <PopUp
            className={betweenAccountsStore.isShowSuccessPopUp ? 'transaction-success' : 'transaction-info'}
            show={betweenAccountsStore.isInternalTransactionCreated || betweenAccountsStore.isShowSuccessPopUp}
            alignOnCenter={true}
            onClose={
              betweenAccountsStore.isShowSuccessPopUp
                ? handleCloseSuccessModal
                : () => betweenAccountsStore.setIsInternalTransactionCreated(false)
            }
          >
            {betweenAccountsStore.isShowSuccessPopUp ? (
              <PopUpSuccessScheme
                onClose={handleCloseSuccessModal}
                message={i18n.getMessage('popUp.message.moneyTransfer')}
              />
            ) : (
              <TransferConfirmationScheme
                isBetweenAccounts={true}
                transferData={{
                  from: betweenAccountsStore.accountFrom?.wallet_number,
                  to: betweenAccountsStore.accountTo?.wallet_number,
                  amount: betweenAccountsStore.amount,
                  currency: betweenAccountsStore.accountFrom?.currency || betweenAccountsStore.accountTo?.currency,
                  description: betweenAccountsStore.description
                }}
                error={betweenAccountsStore?.confirmationPopupError}
                generateSecurityCode={() => betweenAccountsStore.createInternalTransaction(customerNumber)}
                resendSecurityCode={() => betweenAccountsStore.resendTransactionSecurityCode()}
                clearError={() => betweenAccountsStore?.clearConfirmationPopupError()}
                onConfirm={handleVerifyInternalTransaction}
                onClose={() => betweenAccountsStore.setIsInternalTransactionCreated(false)}
              />
            )}
          </PopUp>
        </section>
      )}
    </>
  );
};

BetweenAccounts.propTypes = {
  userAccounts: PropTypes.array,
  customerNumber: PropTypes.string,
  topUpAccount: PropTypes.object,
  betweenAccountsStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userAccounts: stores.userStore?.userAccounts,
  customerNumber: stores.userStore.userData.account?.account_number,
  topUpAccount: stores.cardsStore.topUpAccount,
  betweenAccountsStore: stores.betweenAccountsStore,
  userStore: stores.userStore
}))(observer(BetweenAccounts));
