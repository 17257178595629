import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  const gaTrackingId = process.env.REACT_APP_GTAG_ID;
  if (!gaTrackingId) return;
  ReactGA.initialize(gaTrackingId);
};

export const trackPageView = (path) => {
  if (!path || !ReactGA.isInitialized) return;
  ReactGA.send({ hitType: 'pageview', page: path });
};
export default initializeAnalytics;
