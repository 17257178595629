/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { REGISTRATION_STATUSES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';

import '../RegistrationStyles.scss';
import { PopUp } from 'uikit/PopUp/PopUp';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';

const SuccessAccountRegistration = ({ registrationStore, userStore }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (
      location.pathname === ROUTE_PATHS.REGISTRATION_SUCCESS &&
      registrationStore.registrationStatus !== REGISTRATION_STATUSES.REGISTRATION_STATUS_CONFIRMED
    ) {
      navigate(ROUTE_PATHS.REGISTRATION);
    }
  }, []);

  const onPopUpClose = async () => {
    if (!registrationStore.isAfterUserRegistration) {
      await userStore.loadUserData(null, true);
      navigate(`${ROUTE_PATHS.VERIFICATION}`);
    } else {
      navigate(`${ROUTE_PATHS.DASHBOARD}`);
    }
    registrationStore.resetRegistrationStore();

    // if (!registrationStore.isAfterUserRegistration) {
    //   await userStore.loadUserData(null, true);
    // }
    // navigate(`${ROUTE_PATHS.VERIFICATION}`);
  };

  return (
    <PopUp show={true}>
      <PopUpSuccessScheme
        onClose={onPopUpClose}
        message={i18n.getMessage('popUp.success.registration')}
        buttonText={i18n.getMessage('popUp.success.button')}
      />
    </PopUp>
  );
};

SuccessAccountRegistration.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  registrationStore: stores.registrationStore,
  userStore: stores.userStore
}))(observer(SuccessAccountRegistration));
