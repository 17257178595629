import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n';
import {
  exportCurrentPDF,
  exportPDFList,
  exportTransactionListCSV,
  getDocumentsVerificationTransactionList,
  getTransactionList,
  uploadDocumentsToTransaction
} from 'services/requestAgent';
import { convertBytesToMegabytes, convertDateToServerFilter, debounce, fileExtensionValidation } from 'services/utils';
import { getFromDate, PREDEFINED_FILTERS } from 'components/common/LatestTransactions/utils';
import { MAX_UPLOADED_FILES_SIZE, PAGINATION_SIZES } from 'components/common/constants';

const getFilters = (rangeFilter) => {
  const { from, to } = getFromDate(rangeFilter);
  return {
    search_text: '',
    from_date: new Date(from) || null,
    to_date: new Date(to) || null,
    currencies: [],
    wallets: [],
    types: [],
    transfer_types: [],
    statuses: []
  };
};

class TransactionsStore {
  isFileLoading = false;
  isLoading = false;
  error = null;
  transactionList = [];
  complianceRequestList = [];
  totalElements = null;
  totalPages = null;
  rangeFilter = PREDEFINED_FILTERS.WEEK;
  filters = getFilters(this.rangeFilter);
  transactionFiles = [];
  isCardAccountTransactions = false;

  pagination = {
    size: PAGINATION_SIZES[0],
    page: 0
  };

  latestTransactionSort = {
    sort_column: 'updated_at',
    sort_direction: 'DESC',
    size: 5
  };

  transactionSort = {
    sort_column: null,
    sort_direction: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  setIsFileLoading(status) {
    this.isFileLoading = status;
    this.error = null;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsCardAccountTransactions(status) {
    this.isCardAccountTransactions = status;
  }

  setAccount(account) {
    this.filters.wallets = [account.wallet_number];
  }

  setFilter(fieldName, value) {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  }

  setRangeFilter(filterType, dateRange) {
    this.pagination.page = 0;
    this.rangeFilter = filterType;
    this.filters.from_date = new Date(dateRange.from);
    this.filters.to_date = new Date(dateRange.to);
  }

  clearPredefinedFilter() {
    this.rangeFilter = null;
  }

  clearFilters() {
    this.rangeFilter = PREDEFINED_FILTERS.WEEK;
    this.filters = getFilters(this.rangeFilter);
  }

  setTransactionPage(page) {
    this.pagination.page = page;
  }

  setTransactionPageSize(size) {
    this.pagination.size = size;
  }

  setTransactionSort(sortData) {
    this.transactionSort.sort_column = sortData.sort_column;
    this.transactionSort.sort_direction = sortData.sort_direction;
  }

  addTransactionFiles(files) {
    this.error = null;
    if (!Object.keys(files).length) {
      return null;
    }

    let filesSize = 0;
    if (this.transactionFiles.length > 0) {
      this.transactionFiles.forEach((file) => (filesSize += file.size));
    }
    for (let file in files) {
      if (files[file].size) {
        if (!fileExtensionValidation(files[file].name)) {
          this.error = i18n.getMessage('attachDoc.error.extension');
          return null;
        }
        filesSize += files[file].size;
      }
    }
    if (filesSize > MAX_UPLOADED_FILES_SIZE) {
      // eslint-disable-next-line max-len
      this.error = i18n.getMessage('attachDoc.error.size', {
        maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
      });
      return null;
    }
    this.transactionFiles = [...this.transactionFiles, ...files];
  }

  clearTransactionFiles() {
    this.transactionFiles = [];
  }

  async uploadTransactionFiles(customerNumber, transactionNumber) {
    this.setIsFileLoading(true);
    try {
      const Data = new FormData();
      this.transactionFiles.forEach((file) => {
        if (file.size > MAX_UPLOADED_FILES_SIZE) {
          throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
        } else {
          Data.append('file', file, file?.name);
        }
      });
      const updatedTransaction = await uploadDocumentsToTransaction(customerNumber, transactionNumber, Data);
      runInAction(() => {
        this.isFileLoading = false;
        this.transactionList.content = this.transactionList.map((transaction) => {
          if (transaction?.transaction_number === updatedTransaction.transaction_number) {
            transaction.documents = updatedTransaction.documents;
          }
          return transaction;
        });
        this.transactionFiles = [];
      });

      /* Updating the Documents Verification Transaction List */
      await this.getComplianceRequestList(customerNumber);
    } catch (err) {
      runInAction(() => {
        if (err?.code === 'DOCUMENT_NOT_SAFE') {
          this.error = i18n.getMessage('attachDoc.error.malware');
        } else {
          this.error = err;
        }
        this.isFileLoading = false;
        this.transactionFiles = [];
      });
      throw err;
    }
  }

  removeTransactionFile(fileId) {
    this.transactionFiles = this.transactionFiles.filter((file, index) => index !== fileId);
  }

  async getComplianceRequestList(customerNumber) {
    try {
      this.setIsLoading(true);
      const { content: data } = await getDocumentsVerificationTransactionList(customerNumber);

      runInAction(() => {
        this.complianceRequestList = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async getLatestTransactions(customerNumber, filters = {}) {
    try {
      this.setIsLoading(true);
      const { content: data } = await getTransactionList(customerNumber, { ...this.latestTransactionSort, ...filters });
      runInAction(() => {
        this.transactionList = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  getTransactionsWithDebounce = debounce(this.getTransactions, 400);

  async getTransactions(customerNumber) {
    try {
      this.setIsLoading(true);
      const query = {
        ...this.filters,
        ...this.pagination,
        from_date: convertDateToServerFilter(this.filters.from_date),
        to_date: convertDateToServerFilter(this.filters.to_date),
        ...this.transactionSort
      };

      const {
        content: data,
        total_elements,
        total_pages,
        number,
        size
      } = await getTransactionList(customerNumber, query);

      runInAction(() => {
        this.totalElements = total_elements;
        this.totalPages = total_pages;
        this.pagination.size = size;
        this.pagination.page = number;
        this.transactionList = data;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async downloadCurrentPDF(customerNumber, transactionNumber) {
    try {
      this.setIsFileLoading(true);
      await exportCurrentPDF(customerNumber, transactionNumber);
      this.setIsFileLoading(false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }

  async downloadPDFList(customerNumber) {
    const query = {
      ...this.filters,
      ...this.pagination,
      from_date: convertDateToServerFilter(this.filters.from_date),
      to_date: convertDateToServerFilter(this.filters.to_date),
      ...this.transactionSort
    };

    try {
      this.setIsFileLoading(true);
      await exportPDFList(customerNumber, query);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }

  async downloadCSVList(customerNumber) {
    const query = {
      ...this.filters,
      ...this.pagination,
      from_date: convertDateToServerFilter(this.filters.from_date),
      to_date: convertDateToServerFilter(this.filters.to_date),
      ...this.transactionSort
    };

    try {
      this.setIsFileLoading(true);
      await exportTransactionListCSV(customerNumber, query);
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isFileLoading = false;
      });
      throw err;
    }
  }
}

export default new TransactionsStore();
