import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import { ReactComponent as PhoneIcon } from 'assets/phone-icon.svg';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { getErrorMessageForAlert } from 'services/utils';
import registrationStore from 'stores/registrationStore';
import { ConfirmCodeWrapper } from '../common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { SMS_ERROR_CODE_DISABLE } from '../common/constants';

const Sms = ({ sms, handleSms, resendCode, phone, error, isRegistration, isForgotPassword, authStore }) => {
  const i18n = useContext(i18nContext);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  const forceSwitchToGoogle2FAConfirmation = () => authStore.forceSwitchToGoogle2FAConfirmation();

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
      handleSms(sms, true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
  }, []);

  const handleResendCode = async () => {
    setShowCountDown(true);
    await resendCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (sms) => {
    handleSms(sms);
    setAlertState({ type: '', message: '' });
  };

  return (
    <div className='authorization-form' id='form1'>
      <PhoneIcon className='svg-phone-icon registration-icon' />
      <h1 className='security-code-title'>{i18n.getMessage('sms.title')}</h1>
      {isForgotPassword && (
        <p className='forgot-password-info-alert'>
          {i18n.getMessage('sms.forgotPassword.info1')}
          <br />
        </p>
      )}
      <ConfirmCodeWrapper
        PhoneComponent={
          isRegistration ? (
            <button
              className='unset link-normal'
              onClick={() => {
                registrationStore.changePhoneNumber();
              }}
            >
              {phone}
            </button>
          ) : (
            <strong>{phone}</strong>
          )
        }
        isDisabled={isDisabled}
        error={error}
        onComplete={onComplete}
        showCountDown={showCountDown}
        handleResendCode={handleResendCode}
        onTimerEnd={onTimerEnd}
        alertState={alertState}
        isAuth={true}
      />
      <AuthorizationBackButton
        onClick={() => authStore.setIsLoginSuccess(false)}
        type={'login'}
        handle2Fa={forceSwitchToGoogle2FAConfirmation}
        isGoogle2FAAuth={authStore.isGoogle2FAEnabled}
        confirmationType={authStore.confirmationActionType}
      />
      {isForgotPassword && (
        <p className='forgot-password-info-text'>
          {i18n.getMessage('sms.forgotPassword.info2')}
          {supportEmail && (
            <a className='forgot-password-info-link' href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>
          )}
        </p>
      )}
    </div>
  );
};

Sms.propTypes = {
  sms: PropTypes.string.isRequired,
  phone: PropTypes.string,
  handleSms: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  error: PropTypes.any,
  isRegistration: PropTypes.bool,
  isForgotPassword: PropTypes.bool,
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(Sms));
