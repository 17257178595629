import { makeAutoObservable, runInAction } from 'mobx';
import {
  BACKEND_MODULE_TYPES,
  MAX_UPLOADED_FILES_SIZE,
  TOPIC_MESSAGES_SIZE,
  TOPICS_SIZE
} from 'components/common/constants';
import { getUnreadTopicsAmount } from 'components/Messages/utils';
import {
  confirmMessageCreation,
  confirmTopicCreation,
  createMessage,
  createTopic,
  downloadMessageDocument,
  getMessageList,
  getTopicMessages,
  markTopicAsReadRequest,
  resendSecurityCode,
  uploadMessageDocument
} from 'services/requestAgent';
import { updateConfirmationActionType } from '../services/authUtils';

class MessagesStore {
  isInitialized = false;
  isLoading = false;
  isTopicMessagesLoading = false;
  isFileUploading = false;
  isNewTopicCreated = false;
  isNewMessageCreated = false;
  error = null;
  topics = [];
  unreadTopics = null;
  currentTopicIndex = -1;
  currentTopicMessages = [];
  securityCode = '';
  confirmationId = null;
  uploadedFiles = [];
  newTopic = {
    subject: '',
    message_body: ''
  };
  newMessage = {
    body: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  async initializeData(account_number) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const { content: topics } = await getMessageList(account_number, { size: TOPICS_SIZE });

      topics.sort((a, b) => new Date(b?.last_message?.create_at) - new Date(a?.last_message?.create_at));

      const unreadTopics = getUnreadTopicsAmount(topics);

      runInAction(() => {
        this.topics = topics;
        this.unreadTopics = unreadTopics;
        this.isInitialized = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  resetForms() {
    this.isLoading = false;
    this.error = null;
    this.securityCode = '';
    this.confirmationId = null;
    this.uploadedFiles = [];
    this.newTopic = {
      subject: '',
      message_body: ''
    };
    this.newMessage = {
      body: ''
    };
  }

  clearError() {
    this.error = null;
  }

  clearUploadedFiles() {
    this.uploadedFiles = [];
  }

  setError(error) {
    this.error = error;
  }

  setIsNewTopicCreated(status) {
    this.isNewTopicCreated = status;
  }

  setIsNewMessageCreated(status) {
    this.isNewMessageCreated = status;
  }

  setCurrentTopicIndex(index) {
    this.currentTopicIndex = index;
  }

  getCurrentTopicIndexById(topicId) {
    return this.topics.findIndex((topic) => topic.id === topicId);
  }

  setSecurityCode(code) {
    this.securityCode = code;
  }

  setNewTopicData = (subject, message) => {
    this.newTopic.subject = subject;
    this.newTopic.message_body = message;
  };

  setNewMessageData = (message) => {
    this.newMessage.body = message;
  };

  setIsFileUploading(status) {
    this.isFileUploading = status;
    this.error = null;
  }

  removeAttachment(fileId) {
    this.uploadedFiles = this.uploadedFiles.filter((file) => file?.id !== fileId);
  }

  uploadAttachments(customerNumber) {
    return async ({ target: { files } }) => {
      if (!Object.keys(files).length) {
        return null;
      }
      this.setIsFileUploading(true);
      try {
        const selectedFiles = [...files];
        const Data = new FormData();
        selectedFiles.forEach((file) => {
          if (file.size > MAX_UPLOADED_FILES_SIZE) {
            throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
          } else {
            Data.append('file', file, file?.name);
          }
        });
        const documentArray = await uploadMessageDocument(customerNumber, Data);

        runInAction(() => {
          this.isFileUploading = false;
          this.uploadedFiles = [
            ...this.uploadedFiles,
            ...documentArray.map((file) => ({ name: file?.name, id: file?.id }))
          ];
        });
      } catch (err) {
        runInAction(() => {
          this.isFileUploading = false;
          this.error = { type: 'attachDoc', ...err };
        });
      }
    };
  }

  async downloadAttachment(customerNumber, fileId) {
    try {
      runInAction(() => {
        this.isFileUploading = true;
      });
      await downloadMessageDocument(customerNumber, fileId, false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isFileUploading = false;
      });
    }
  }

  async generateNewTopicSecurityCode(customerNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { confirmation_id: confirmationId, confirmation_type: confirmationType } = await createTopic(
        customerNumber,
        {
          ...this.newTopic,
          documents: this.uploadedFiles.map((file) => file.id)
        }
      );

      updateConfirmationActionType(confirmationType);

      runInAction(() => {
        this.confirmationId = confirmationId;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async generateNewMessageSecurityCode(customerNumber, topicId) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { confirmation_id: confirmationId, confirmation_type: confirmationType } = await createMessage(
        customerNumber,
        topicId,
        {
          ...this.newMessage,
          documents: this.uploadedFiles.map((file) => file.id)
        }
      );

      updateConfirmationActionType(confirmationType);

      runInAction(() => {
        this.confirmationId = confirmationId;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async resendMessageSecurityCode() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      await resendSecurityCode(BACKEND_MODULE_TYPES.MESSENGER, this.confirmationId);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async confirmNewTopicCreation(customerNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const newTopic = await confirmTopicCreation(customerNumber, {
        code: this.securityCode,
        confirmation_id: this.confirmationId
      });

      runInAction(() => {
        this.uploadedFiles = [];
        this.topics = [newTopic, ...this.topics];
        this.isNewTopicCreated = true;
        this.resetForms();
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.securityCode = '';
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async getCurrentTopicMessagesFilesUrls(customerNumber) {
    try {
      for (const message of this.currentTopicMessages) {
        if (message.documents?.length) {
          for (const document of message.documents) {
            if (!document.url) {
              const blobUrl = await downloadMessageDocument(customerNumber, document.id, true);
              runInAction(() => {
                document.url = blobUrl;
              });
            }
          }
        }
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    }
  }

  async markTopicAsRead(accountNumber, topicId, topicIndex) {
    try {
      runInAction(() => {
        this.isTopicMessagesLoading = true;
      });

      const updatedTopic = await markTopicAsReadRequest(accountNumber, topicId);

      runInAction(() => {
        this.unreadTopics--;
        this.topics[topicIndex] = updatedTopic;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isTopicMessagesLoading = false;
      });
    }
  }

  async getCurrentTopicMessages(customerNumber, topicId) {
    try {
      runInAction(() => {
        this.isTopicMessagesLoading = true;
      });

      const { content: messages } = await getTopicMessages(customerNumber, topicId, { size: TOPIC_MESSAGES_SIZE });

      runInAction(() => {
        this.currentTopicMessages = messages;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isTopicMessagesLoading = false;
      });
    }
  }

  async confirmNewMessageCreation(customerNumber, topicId) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const newMessage = await confirmMessageCreation(customerNumber, topicId, {
        code: this.securityCode,
        confirmation_id: this.confirmationId
      });

      runInAction(() => {
        this.uploadedFiles = [];
        this.currentTopicMessages = [...this.currentTopicMessages, newMessage];
        this.topics[this.currentTopicIndex].last_message = newMessage;
        this.topics.sort((a, b) => new Date(b?.last_message?.create_at) - new Date(a?.last_message?.create_at));
        this.isNewMessageCreated = true;
        this.setCurrentTopicIndex(0);
        this.resetForms();
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.securityCode = '';
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default new MessagesStore();
