import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RichTextEditor from 'react-rte';
import { convertHtmlToString, convertMessageToHtmlAndTrimSpaces } from '../utils';
import { isFullSecurityCode } from '../../utils';
import i18nContext from 'components/i18n-context';
import {
  CONFIRMATION_HANDLE_TYPES,
  MAX_MESSAGE_LENGTH,
  MAX_TOPIC_SUBJECT_LENGTH,
  MAX_UPLOADED_FILES_SIZE,
  SMS_ERROR_CODE_DISABLE
} from 'components/common/constants';
import Loader from 'components/common/Loader';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { getSecureUserPhone } from 'services/authUtils';
import { convertBytesToMegabytes, getErrorMessageForAlert, messageRegExp } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import '../Messages.scss';

const NewMessageForm = ({
  securityCode,
  setSecurityCode,
  generateSecurityCode,
  resendSecurityCode,
  newTopicData,
  setNewTopicData,
  isLoading,
  error,
  clearError,
  createNewTopic,
  uploadedFiles,
  uploadDocuments,
  removeAttachment
}) => {
  const i18n = useContext(i18nContext);
  const [isShowConfirmationCode, setIsShowConfirmationCode] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [messageValue, setMessageValue] = useState(
    newTopicData?.message_body
      ? RichTextEditor.createValueFromString(newTopicData?.message_body, 'html')
      : RichTextEditor.createEmptyValue()
  );

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      subject: newTopicData?.subject ? newTopicData?.subject : '',
      message: newTopicData?.message_body ? newTopicData?.message_body : ''
    },
    validationSchema: Yup.object({
      subject: Yup.string()
        .required(i18n.getMessage('messages.error.subject.empty'))
        .max(
          MAX_TOPIC_SUBJECT_LENGTH,
          i18n.getMessage('messages.error.subject.maxSize', { size: MAX_TOPIC_SUBJECT_LENGTH })
        )
        .matches(messageRegExp, i18n.getMessage('messages.error.message.invalidFormat')),
      message: Yup.string()
        .required(i18n.getMessage('messages.error.message.empty'))
        .max(MAX_MESSAGE_LENGTH, i18n.getMessage('messages.error.message.maxSize', { size: MAX_MESSAGE_LENGTH }))
        .matches(messageRegExp, i18n.getMessage('messages.error.message.invalidFormat'))
    }),
    onSubmit: (values) => {
      setNewTopicData(values.subject, convertMessageToHtmlAndTrimSpaces(messageValue));
      generateSecurityCode().then(() => {
        setIsShowConfirmationCode(true);
        setShowCountDown(true);
        setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, setFieldValue, submitCount } = form;

  useEffect(() => {
    clearError();
    setNewTopicData('', '');
  }, []);

  useEffect(() => {
    setFieldValue('message', convertHtmlToString(messageValue));
  }, [messageValue, setFieldValue]);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setSecurityCode('');
    } else {
      setAlertState({ type: '', message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const onEditMessage = () => {
    setIsShowConfirmationCode(false);
    setIsDisabled(false);
    setSecurityCode('');
    setAlertState({ type: '', message: '' });
  };

  const onConfirm = async () => {
    await createNewTopic();
  };

  return (
    <div className='messages-right-side'>
      <form action='#' id='send' onSubmit={handleSubmit} className='new-message-form'>
        <Input
          className='new-message-form-subject-input'
          type={'text'}
          autoFocus={true}
          label={i18n.getMessage('messages.form.label.subject')}
          name={'subject'}
          value={values.subject}
          isDisabled={isShowConfirmationCode}
          onChange={handleChange}
          onBlur={() => validateField('subject')}
          initialStatus={submitCount}
          error={errors?.subject}
          max={MAX_TOPIC_SUBJECT_LENGTH}
          /* eslint-disable-next-line max-len */
          subText={i18n.getMessage('messages.form.input.message.symbolsLeft', {
            amount: MAX_TOPIC_SUBJECT_LENGTH - values.subject.length
          })}
        />
        <Input
          className='chat-form-input'
          type={'text-editor'}
          label={i18n.getMessage('messages.form.input.message.label')}
          name={'message'}
          isDisabled={isShowConfirmationCode}
          value={messageValue}
          onChange={setMessageValue}
          onBlur={() => validateField('message')}
          initialStatus={submitCount}
          max={MAX_MESSAGE_LENGTH}
          rows={4}
          error={errors?.message}
          /* eslint-disable-next-line max-len */
          subText={i18n.getMessage('messages.form.input.message.symbolsLeft', {
            amount: MAX_MESSAGE_LENGTH - values.message.length
          })}
        />
        <AttachDoc
          label={i18n.getMessage('messages.form.attachDoc.label', { amount: uploadedFiles.length })}
          className='new-message-form-attachments'
          type='file'
          size={MAX_UPLOADED_FILES_SIZE}
          isDisabled={isShowConfirmationCode}
          files={uploadedFiles}
          onChange={uploadDocuments}
          onRemoveFiles={removeAttachment}
          error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
          hintText={i18n.getMessage('attachDoc.hint.text', {
            maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
          })}
        />
        {isShowConfirmationCode ? (
          <>
            <ConfirmCodeWrapper
              PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
              isDisabled={isDisabled}
              error={!error?.type && error}
              onComplete={onComplete}
              showCountDown={showCountDown}
              handleResendCode={handleResendCode}
              onTimerEnd={onTimerEnd}
              alertState={alertState}
              isMessenger={true}
              confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
            />
            <div className={'actions-wrapper'}>
              <Button className={'edit-message-button'} size='large' type='outline' onClick={onEditMessage}>
                {i18n.getMessage('messages.button.editMessage')}
              </Button>
              <Button
                className={'confirm-button'}
                type={'primary'}
                size={'large'}
                fullWidth={true}
                isDisabled={!isFullSecurityCode(securityCode)}
                onClick={onConfirm}
              >
                {i18n.getMessage('messages.button.confirm')}
              </Button>
            </div>
          </>
        ) : (
          <Button
            className={'new-message-form-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
            isDisabled={isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('messages.button.sendMessage')}
          </Button>
        )}
      </form>
    </div>
  );
};

NewMessageForm.propTypes = {
  securityCode: PropTypes.string.isRequired,
  setSecurityCode: PropTypes.func.isRequired,
  generateSecurityCode: PropTypes.func.isRequired,
  resendSecurityCode: PropTypes.func.isRequired,
  newTopicData: PropTypes.object.isRequired,
  setNewTopicData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.any,
  clearError: PropTypes.func.isRequired,
  createNewTopic: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  uploadDocuments: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired
};

export default NewMessageForm;
