import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';
import i18nContext from 'components/i18n-context';
import { convertUTCtoGB } from 'components/utils';
import { amountFormattedValue } from 'services/utils';
import { ActionButton } from 'uikit/ActionButton/ActionButton';
import './LatestPaymentObligations.scss';

export const LatestPaymentObligationItem = ({ obligation, onInfoClick, isMobile }) => {
  const i18n = useContext(i18nContext);
  const createdAt = convertUTCtoGB(obligation.created_at);
  const amount = `${amountFormattedValue(obligation.amount)} ${obligation.currency}`;

  if (isMobile) {
    return (
      <div className='obligation-item-mobile'>
        <div className='item-mobile-top'>
          <div className='item-mobile-date'>
            <CalendarIcon className='icon' />
            <p>{`${createdAt.date} ${createdAt.time}`}</p>
          </div>
        </div>
        <div className='item-mobile-amount'>
          <WalletIcon className='icon' />
          <p className='item-amount-text'>{amount}</p>
          <ActionButton onClick={onInfoClick} />
        </div>
      </div>
    );
  }

  return (
    <tr className='obligation-item'>
      <td className='obligation-date'>
        <p>{createdAt.date}</p>
        <p>{createdAt.time}</p>
      </td>
      <td className='obligation-purpose'>{obligation.comment}</td>
      <td className={`obligation-amount`}>
        <p className='amount-text'>{amount}</p>
      </td>
      <td className='obligation-exclamation'>
        <ActionButton tooltipText={i18n.getMessage('popUp.obligationsDetails')} onClick={onInfoClick} />
      </td>
    </tr>
  );
};

LatestPaymentObligationItem.propTypes = {
  obligation: PropTypes.object.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};
