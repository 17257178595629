import * as Yup from 'yup';
import { PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import {
  LATIN_LETTERS_REG_EXP,
  LATIN_LETTERS_AND_DIGITS_REG_EXP,
  START_WITH_GB_REG_EXP,
  getLatinLettersDigitsSymbolsRegExp,
  ONLY_DIGITS_REG_EXP,
  BIC_REG_EXP
} from './utils';
import { getMaxDescriptionSize } from '../../utils';

export default ({
  i18n,
  onChange,
  onSubmit,
  customerNumber,
  model,
  currency,
  previousTransaction,
  paymentMethod,
  providerType
}) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    amount: `${previousTransaction?.amount || ''}`,
    recipient_wallet: previousTransaction?.counterparty?.iban,
    recipient_type: RECIPIENT_TYPES[previousTransaction?.recipient_type] || RECIPIENT_TYPES.COMPANY,
    company_name: previousTransaction?.counterparty?.party_name,
    first_name: previousTransaction?.counterparty?.party_name.split(' ')[0] || null,
    last_name: previousTransaction?.counterparty?.party_name.split(' ')[1] || null,
    comment: previousTransaction?.comment,
    documents: null,
    registration_number: previousTransaction?.counterparty?.registration_number,
    beneficiary_city: previousTransaction?.counterparty?.party_city,
    beneficiary_post_code: previousTransaction?.counterparty?.post_code,
    beneficiary_address: previousTransaction?.counterparty?.party_address,
    beneficiary_country: previousTransaction?.counterparty?.party_country,
    bank_country: previousTransaction?.counterparty?.bank_country,
    bank_name: previousTransaction?.counterparty?.bank_name,
    account_number: previousTransaction?.counterparty?.account_number || '',
    bic: previousTransaction?.counterparty?.bic || ''
  },
  initialStatus: { company_name: true, recipient_wallet: true },
  validationSchema: Yup.object().shape(
    {
      recipient_wallet: Yup.string().when('account_number', {
        is: (account_number) => !account_number,
        then: (schema) =>
          schema
            .nullable(true)
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .max(34, i18n.getMessage('client.validation.message.maxSize', { size: 34 }))
            .trim()
            // trick for using `currency` variable in checking value in validation
            .when('recipient_type', (recipient_type, schema) => {
              if (currency === 'GBP' && paymentMethod !== PAYMENT_METHOD.SWIFT) {
                return schema.matches(START_WITH_GB_REG_EXP, {
                  message: i18n.getMessage('client.validation.message.startWithGB')
                });
              }
            })
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            })
      }),
      bank_name: Yup.string().when('recipient_wallet', {
        is: false,
        then: (schema) =>
          schema
            .nullable(true)
            .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            })
      }),
      account_number: Yup.string().when('recipient_wallet', {
        is: false,
        then: (schema) =>
          schema
            .nullable(true)
            .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
            .matches(ONLY_DIGITS_REG_EXP, { message: i18n.getMessage('client.validation.message.onlyDigits') })
      }),
      bic: Yup.string().when('recipient_wallet', {
        is: false,
        then: (schema) =>
          schema
            .nullable(true)
            .max(12, i18n.getMessage('error.fieldSize', { amount: '12' }))
            .matches(BIC_REG_EXP, { message: i18n.getMessage('client.validation.message.bic') })
      }),
      registration_number: Yup.string()
        .nullable(true)
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
        .matches(ONLY_DIGITS_REG_EXP, { message: i18n.getMessage('client.validation.message.onlyDigits') }),
      beneficiary_city: Yup.string()
        .nullable(true)
        .max(140, i18n.getMessage('error.fieldSize', { amount: '140' }))
        .matches(LATIN_LETTERS_REG_EXP, { message: i18n.getMessage('client.validation.message.isOnlyLatinLetters') }),
      beneficiary_post_code: Yup.string()
        .nullable(true)
        .max(12, i18n.getMessage('error.fieldSize', { amount: '12' }))
        .matches(getLatinLettersDigitsSymbolsRegExp('-'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“-“'
          })
        }),
      beneficiary_address: Yup.string()
        .nullable(true)
        .max(250, i18n.getMessage('error.fieldSize', { amount: '250' }))
        .matches(getLatinLettersDigitsSymbolsRegExp('.,'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“.“, ","'
          })
        }),
      amount: Yup.string().nullable(true).required(i18n.getMessage('client.validation.message.isEmpty')),
      recipient_type: Yup.string().nullable(false).required(i18n.getMessage('client.validation.message.isEmpty')),
      company_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === 'Company') {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(getLatinLettersDigitsSymbolsRegExp('.,-'), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: '“.“, “,“, “-“'
                })
              });
          }
        }),
      first_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      last_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .nullable(true)
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      comment: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .matches(getLatinLettersDigitsSymbolsRegExp('.,-'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '.,-'
          })
        })
        .max(
          getMaxDescriptionSize(providerType, paymentMethod, currency),
          i18n.getMessage('client.validation.message.maxSize', {
            size: getMaxDescriptionSize(providerType, paymentMethod, currency)
          })
        )
    },
    [['account_number', 'recipient_wallet']]
  ),
  onSubmit: (values) => {
    onChange(values);
    onSubmit({ customerNumber, providerData: values, model });
  }
});
